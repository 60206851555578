import { ACCURACY } from './constants';

export function normalizeBounds(value, minimum, maximum) {

  if (value < minimum)
    return minimum;

  if (value > maximum)
    return maximum;

  return value;
}
// ADU JLC - 9A00017767 - suggested products don't add all to basket - start
// pass and check delta to check if the value has been descreased below minimum
export function normalizeBoundsEmpty(value, minimum, maximum, wasProductLoadedFromsuggestedProduct, delta) {
  if(wasProductLoadedFromsuggestedProduct && delta.toString().startsWith('-'))
  {
    if(value < minimum)
    return '';
  }
    if (value < minimum)
    return minimum;

  if (value > maximum)
    return maximum;

  return value;
}
// ADU JLC - 9A00017767 - suggested products don't add all to basket - end

export function strip(number) {
  return parseFloat(number.toFixed(ACCURACY));
}